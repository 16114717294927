import React, { useState, useEffect } from 'react';
import '../assets/css/style.bundle.css'
import Slider from 'react-slick';
import '../assets/css/style.css';
import '../assets/css/swiper-bundle.min.css';
import '../assets/css/landing.css';
import headerlogo from '../assets/images/hey-visuals-1-png-no-bg.png';
import footerimage from '../assets/images/hey-visuals-1-png-no-bg.png';
import backnoice from '../assets/images/noise.png';
import illus from '../assets/images/illus.png';
import blackslider0 from '../assets/images/0_0.png';
import blackslider1 from '../assets/images/0_1.png';
import blackslider2 from '../assets/images/0_3.png';
import blackslider3 from '../assets/images/0_4.png';
import blackslider4 from '../assets/images/0_9.png';
import blackslider5 from '../assets/images/0_6.png';
import blackslider6 from '../assets/images/0_7.png';
import blackslider7 from '../assets/images/0_8.png';
import blackslider8 from '../assets/images/0_9.png';
import blackslider9 from '../assets/images/0_4.png';
import industslider0 from '../assets/images/ai-image.png';
import industslider1 from '../assets/images/feature-1.png';
import industslider2 from '../assets/images/iron-man.jpg';
import industslider3 from '../assets/images/avtar-after.png';
import heroimg0 from '../assets/images/hero-gallary-img_1.png';
import heroimg2 from '../assets/images/hero-gallary-img_2.png';
import heroimg3 from '../assets/images/hero-gallary-img_3.png';
import heroimg4 from '../assets/images/hero-gallary-img_4.png';
import heroimg5 from '../assets/images/hero-gallary-img_5.png';
import heroimg6 from '../assets/images/hero-gallary-img_6.png';
import heroimg7 from '../assets/images/hero-gallary-img_7.png';
import heroimg8 from '../assets/images/hero-gallary-img_8.png';
import heroimg9 from '../assets/images/hero-gallary-img_9.png';
import heroimg10 from '../assets/images/hero-gallary-img_10.png';
import heroimg11 from '../assets/images/hero-gallary-img_11.png';
import heroimg12 from '../assets/images/hero-gallary-img_12.png';
import heroimg13 from '../assets/images/hero-gallary-img_13.png';
import banimg from '../assets/images/confetti (2).png';
import banimg1 from '../assets/images/flash-sale.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typed from 'typed.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const Navbar = () => {

  const BenefitCard = ({ iconClass, title, description }) => (
    <div className="col-md-6 col-xl-4">
      <div className="card mb-5 mb-xxl-8">
        <div className="card-body pb-3">
          <div className="icon py-6">
            <i
              className={`p-4 px-6 rounded-4 ${iconClass} fs-2x text-white`}
              style={{ background: 'linear-gradient(90deg, #1179fc 25%, #dc82fc)' }}
            ></i>
          </div>
          <h1 className="fs-3x">{title}</h1>
          <p className="fs-3" >{description}</p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const typed = new Typed('.typetext', {
      strings: ["Influencers.", "Youtuber.", "Content Creator.", "Marketers."],
      typeSpeed: 80,
      backSpeed: 40,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set scrolled state based on scroll position
      setScrolled(window.scrollY > 0);
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        }
      }, {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4.6,
        }
      }, {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        }
      }, {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3.98,
        }
      },{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.9,
        }
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3.6,
        }
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.38,
        }
      }, {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
        }
      }, {
        breakpoint: 930,
        settings: {
          slidesToShow: 3,
        }
      }, {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.5,
        }
      }, {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        }
      }, {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.32,
        }
      }, {
        breakpoint: 405,
        settings: {
          slidesToShow: 1.23,
        }
      }, {
        breakpoint: 380,
        settings: {
          slidesToShow: 1.18,
        }
      }, {
        breakpoint: 350,
        settings: {
          slidesToShow: 1.04,
        }
      }, {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
        }
      }, {
        breakpoint: 315,
        settings: {
          slidesToShow: .98,
        }
      }, {
        breakpoint: 307,
        settings: {
          slidesToShow: .89,
        }
      }
    ]
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    rtl: true,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        }
      }, {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4.6,
        }
      }, {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        }
      }, {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3.98,
        }
      }, {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.9,
        }
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3.6,
        }
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.38,
        }
      }, {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
        }
      }, {
        breakpoint: 930,
        settings: {
          slidesToShow: 3,
        }
      }, {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.5,
        }
      }, {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        }
      }, {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.32,
        }
      }, {
        breakpoint: 405,
        settings: {
          slidesToShow: 1.23,
        }
      },{
        breakpoint: 380,
        settings: {
          slidesToShow: 1.18,
        }
      }, {
        breakpoint: 350,
        settings: {
          slidesToShow: 1.04,
        }
      }, {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
        }
      }, {
        breakpoint: 315,
        settings: {
          slidesToShow: .98,
        }
      }, {
        breakpoint: 307,
        settings: {
          slidesToShow: .89,
        }
      }
    ]
  }
  const [activeLink, setActiveLink] = useState('#home');

  const handleClick = (link) => {
    setActiveLink(link);  // Update active link on click
  };
  const [signInInProgress, setSignInInProgress] = useState(false);

  // google signin 

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      if (signInInProgress) return; // Prevent multiple sign-ins
      setSignInInProgress(true);
       
      if (window.google && window.google.accounts) {
        
        window.google.accounts.id.initialize({
          client_id: '137194797657-1k20ju7d9jjhddjtcjp4rp96bk3ulbq3.apps.googleusercontent.com',
          callback: handleCredentialResponse,
          auto_select: true, // Set to false for testing
        });
        window.google.accounts.id.prompt();
      } else {
        console.error("Google API not loaded properly.");
      }
    };

    const handleCredentialResponse = (response) => {
       
      if (response.credential) {
        window.location.href = 'https://app.heyvisuals.com/api/auth/google/client-login?id_token=' +
        response.credential;
      } 
    };

    const loadGoogleScript = () => {
      if (document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
         
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
         
        initializeGoogleSignIn();
      };
      script.onerror = (error) => {
        console.error("Failed to load Google script:", error);
      };
      document.body.appendChild(script);
    };

    if (!window.google || !window.google.accounts) {
      loadGoogleScript();
    } else {
      
      initializeGoogleSignIn();
    }
  }, [signInInProgress]);

  useEffect(() => {
    // Initialize the countdown timer (you can customize this logic based on your needs)
    const timerId = setInterval(() => {
      // Countdown logic goes here, for example, setting a timer for the sale
       
    }, 1000);
    return () => clearInterval(timerId); // Cleanup the timer on component unmount
  }, []);
  const [isNavCollapsed, setNavCollapsed] = useState(true);

  const handleNavToggle = () => {
    setNavCollapsed(!isNavCollapsed);
  };

  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [blackPTopPadding, setBlackPTopPadding] = useState('0px');
  const [mainNavMargin, setMainNavMargin] = useState('80px');
  const [isAdditionalMarginRemoved, setIsAdditionalMarginRemoved] = useState(false); // Tracks class removal

  // Function to handle the close button click
  const handleCloseBanner = () => {
    setIsBannerVisible(false);
    // setMainNavMargin('0px'); // Adjust margin-top of the main-nav
    setBlackPTopPadding('-100px');
    setMainNavMargin('0px');
    setIsAdditionalMarginRemoved(true); // Mark the class for removal
  };

  // google signin popup

  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-V2TQDLZKQZ';
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics after script loads
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-V2TQDLZKQZ');
    };

    // Cleanup the script if component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [countdown, setCountdown] = useState('');

  // Function to get the next expiration date (midnight of the next day)
  const getNextExpirationDate = (currentDate) => {
    let nextExpirationDate = new Date(currentDate);
    nextExpirationDate.setHours(57, 0, 0, 0); // Midnight of the next day
    return nextExpirationDate;
  };

  // Countdown Timer Effect (updates every second)
  useEffect(() => {
    const expiration = getNextExpirationDate(new Date());
    const callTime = expiration.getTime();

    const interval = setInterval(() => {
      const now = new Date();
      const nowTime = now.getTime();
      const distance = callTime - nowTime;

      if (distance > 0) {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Update countdown state with the new time
        setCountdown(
          <p style={{ fontWeight: 'bold', fontSize: '24px', color: '#fff', width: '100%', border: 'none' }}>
            <span style={{ color: '#fff', }}> {hours}  <small> HRS</small></span>
            <span style={{ color: '#fff' }}>  {minutes} <small> MINS</small></span>
            <span style={{ color: '#fff' }}> {seconds} <small> SECS</small></span>
          </p>
        );
      } else {
        // Reset the timer if it reaches 0
        const newExpiration = getNextExpirationDate(new Date());
        setCountdown('0 DAYS 00 HRS 00 MINS 00 SECS');
        clearInterval(interval);

      }
    }, 1000);

    // Cleanup the interval when component is unmounted
    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <section class="d-flex flex-column h-100  homepage-landing" style={{ background: 'rgb(245, 245, 245)' }}>
        {isBannerVisible && (
          <div className="at-banner">
              <a href="https://app.heyvisuals.com/">
            <div className="at-banner__content">
              <div className="mobile-row">
                <div className="at-banner__text">
                  <div className="title">
                    <span style={{ fontWeight: 700 }}>
                      Black Friday <span style={{ color: 'red' }}>Sale</span>
                    </span>
                    <img src={banimg1} alt="" width="40px" />
                  </div>
                </div>

                <span className="d-none d-md-block" style={{ fontSize: '24px' }}>
                  |
                </span>
                <span className="disc">
                  Get 50% off{' '}
                  <img src={banimg} alt="" width="40px" />
                </span>
              </div>
              <div className="countdown_timer" id="blackfriday-timer1">{countdown}</div>
              
              {/* <a className="at-banner__button" href="https://app.heyvisuals.com/login">GET IT NOW</a> */}
            </div></a>
            <div className="at-banner__close" onClick={handleCloseBanner} >
            </div>
          </div>
        )}
      </section>


      <section>
        <header style={{ zIndex: 9999 }}>
          <nav className={`main-nav  navbar navbar-expand-md custom-nav navbar-light fixed-top pt-2 pb-4 ${scrolled ? 'scrolled' : ''}`} style={{ marginTop: mainNavMargin }}>
            <div className="container">
              <div className="aside-logo" id="kt_aside_logo">
                <a href="https://app.heyvisuals.com/" className="d-flex align-items-center text-black fw-normal">
                  <img src={headerlogo} alt="Hey Visuals Logo" style={{ maxWidth: '200px' }} />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleNavToggle}
                aria-controls="navbarCollapse"
                aria-expanded={!isNavCollapsed}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`} id="navbarCollapse">
                <ul className="navbar-nav mx-auto mb-2 mb-md-0">
                  <li className="nav-item mx-2">
                    <a
                      href="#home"
                      className={`nav-link fs-2 p-0 fw-bold mx-4 my-2 ${activeLink === '#home' ? 'active' : ''}`}
                      onClick={() => handleClick('#home')}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item mx-2">
                    <a
                      href="#feature"
                      className={`nav-link fs-2 p-0 fw-bold mx-4 my-2 ${activeLink === '#feature' ? 'active' : ''}`}
                      onClick={() => handleClick('#feature')}
                    >
                      Feature
                    </a>
                  </li>
                  <li className="nav-item mx-2">
                    <a
                      href="#benefits"
                      className={`nav-link fs-2 p-0 fw-bold mx-4 my-2 ${activeLink === '#benefits' ? 'active' : ''}`}
                      onClick={() => handleClick('#benefits')}
                    >
                      Benefits
                    </a>
                  </li>
                  <li className="nav-item mx-2">
                    <a
                      href="#faq"
                      className={`nav-link fs-2 p-0 fw-bold mx-4 my-2 ${activeLink === '#faq' ? 'active' : ''}`}
                      onClick={() => handleClick('#faq')}
                    >
                      FAQ
                    </a>
                  </li>
                </ul>
                <div className="d-flex align-items-center nav-navigations" role="search">
                  <a
                    href="https://app.heyvisuals.com/"
                    className="me-10 text-decoration-none d-flex align-items-center justify-content-center Btn"
                  >
                    Get Started Free
                  </a>
                  <div className="vr"></div>
                  <a href="https://app.heyvisuals.com/" className="fw-bold login-btn fs-3 ms-5">
                    Log in <i className="bi bi-door-open-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </section>

      <section>
        <main className="flex-shrink-0">
          <section className={`hero-section  text-center position-relative ${ isAdditionalMarginRemoved ? '' : 'additionalMargin'}`} style={{ padding: "60px 15px 0" }} id="home">
            <div className="container-fluid">
              <div className="row container mx-auto align-items-center">
                <div className="col-md-12">
                  <div className="hero-content">
                    <h1 className="home-heading fs-5x">
                      Create hours of content in minutes using <span className="text-linear">Hey Visuals</span>
                    </h1>
                    <p className="sub-head my-xl-10 mx-auto fs-2x fw-semibold">
                      Say goodbye to endless searching for what to post.
                      We have the perfect solution to make your life easier.
                    </p>
                    <a
                      href="https://app.heyvisuals.com/"
                      className="mt-xl-10 text-decoration-none d-flex align-items-center mx-auto justify-content-center Btn w-200px fs-3"
                      type="submit"
                    >
                      Start now
                    </a>
                    <ul className="list-inline mt-5 cuslist">
                      <li className="list-inline-item text-black-50 fs-5">
                        <i className="bi bi-credit-card-fill fs-4"></i> No credit card required
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="hero-image-wrapper">
                <div class="row align-items-end p-0">
                  <div class="col">
                    <div class="hero-img mb-3">
                      <img src={heroimg0} alt="im" />
                    </div>
                    <div class="hero-img mb-5">
                      <img src={heroimg2} alt="im" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="hero-img mb-3">
                      <img src={heroimg3} alt="im" />
                    </div>
                    <div class="hero-img mb-5">
                      <img src={heroimg4} alt="im" />
                    </div>
                  </div>
                  <div class="col d-none d-md-block">
                    <div class="hero-img mb-3">
                      <img src={heroimg5} alt="im" />
                    </div>
                    <div class="hero-img mb-5">
                      <img src={heroimg6} alt="im" />
                    </div>
                  </div>
                  <div class="col  d-none d-md-block">
                    <div class="hero-img mb-5">
                      <img src={heroimg7} alt="im" />
                    </div>
                  </div>
                  <div class="col  d-none d-md-block">
                    <div class="hero-img mb-3">
                      <img src={heroimg8} alt="im" />
                    </div>
                    <div class="hero-img mb-5">
                      <img src={heroimg9} alt="im" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="hero-img mb-3">
                      <img src={heroimg10} alt="im" />
                    </div>
                    <div class="hero-img mb-5">
                      <img src={heroimg11} alt="im" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="hero-img mb-3">
                      <img src={heroimg12} alt="im" />
                    </div>
                    <div class="hero-img mb-5">
                      <img src={heroimg13} alt="im" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </section>


      <section
        className="industries-area bg_color_black py-20"
        style={{ background: "#e7ecf5" }}
        id="feature"
      >
        <div className="container">
          <h1 className="text-center fs-4x py-5">
            Check out the exciting features of{" "}
            <span className="text-linear">Hey Visuals!</span>
          </h1>
          <div className="row pt-60 gy-4 justify-content-center">
            <div className="col-lg-6 col-xl-5 col-md-6">
              <div className="industries-box position-relative overflow-hidden z-index-one shadow-sm">
                <div className="industries-img overflow-hidden">
                  <img
                    className="w-100"
                    src={industslider0}
                    alt="AI Img Generation"
                  />
                </div>
                <div className="industries-info">
                  <div className="card-content">
                    <h1 className="fs-3x text-black">
                      Faster AI <span className="text-linear">Image</span>{" "}
                      <span className="text-linear">Generation</span>
                    </h1>
                    <p className="fs-4 text-black">
                      Creating stunning images is a demand of Instagram to get
                      more visitors and followers to your account. However, it
                      takes time! With Hey Visuals, it will take only a few
                      seconds to generate spectacular images.
                    </p>
                  </div>{" "}
                  <br />
                  <a
                    href="https://app.heyvisuals.com/"
                    className="btn mt-5 home-btn"
                    role="button"
                  >
                    Let's Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-5 col-md-6">
              <div className="industries-box position-relative overflow-hidden z-index-one">
                <div className="industries-img overflow-hidden">
                  <img
                    className="w-100"
                    src={industslider1}
                    alt="Write Captions"
                  />
                </div>
                <div className="industries-info">
                  <div className="card-content">
                    <h1 className="fs-3x text-black">
                      Write Captions <span className="text-linear">In Seconds</span>
                    </h1>
                    <p className="fs-4 text-black">
                      Writing catchy lines and descriptions for the posts may be
                      overwhelming as it takes your attention to grab people's
                      attention with words. Hey Visuals has the feature to
                      generate amazing captions in no time for every Instagram
                      user.
                    </p>
                  </div>
                  <br />
                  <a
                    href="https://app.heyvisuals.com/"
                    className="btn mt-5 home-btn"
                    role="button"
                  >
                    Let's Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-10 col-md-12">
              <div className="industries-box position-relative overflow-hidden z-index-one">
                <div className="row">
                  <div className="col-md-6">
                    <div className="industries-info">
                      <div className="card-content">
                        <h1 className="fs-3x text-black">
                          Make Your <span className="text-linear">Avatar</span>
                        </h1>
                        <p className="fs-4 text-black">
                          The goal of my AI avatar is to bridge the gap between
                          human and machine, offering a seamless, intuitive
                          experience that feels less like interacting with software
                          and more like conversing with a helpful, knowledgeable
                          companion.
                        </p>
                      </div>
                      <a
                        href="https://app.heyvisuals.com/"
                        className="btn mt-5 home-btn"
                        role="button"
                      >
                        Let's Get Started
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5 mt-md-0">
                    <div class="main-container">
                      <div class="topContainer">
                        <div class="image-container">
                          <ReactCompareSlider
                            itemOne={<ReactCompareSliderImage src={industslider2} alt="Image one" />}
                            itemTwo={<ReactCompareSliderImage src={industslider3} alt="Image two" />}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="Benefits py-xl-20 py-15" id="benefits">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="benefit-head text-center pb-10">
                <h1 className="ctexts">
                  <span
                    className="text-light"
                    style={{ textShadow: '0 5px 35px rgb(255 255 255 / 40%)' }}
                  >
                    Hey Visuals
                  </span>{' '}
                  provides various advantages for
                  <br />
                  <span className="text-linear fw-bolder typetext"></span>
                </h1>
              </div>

              <div className="row benefit-points align-items-center">
                <BenefitCard
                  iconClass="bi bi-lightbulb-fill"
                  title="Unique Image Ideas"
                  description="Many Instagram users feel that they are lacking the spice they need for their posts. With Hey Visuals, it's a piece of cake to design the graphics for you with new and unique ideas."
                />
                <BenefitCard
                  iconClass="bi bi-chat-heart-fill"
                  title="Captivating Captions"
                  description="The most important yet sometimes overlooked part is the captions for your spectacular Instagram posts. Hey Visuals covers it for you and generates the most engaging captions for your posts."
                />
                <BenefitCard
                  iconClass="bi bi-bar-chart-line-fill"
                  title="Improve Engagement"
                  description="Likes, comments, and shares are something every Instagram user craves and shows how much your Instagram content is valuable. With eye-catching images and interesting captions, you are going to win this game with Hey Visuals."
                />

                <div className="col-xl-12 col-md-6 text-center pt-xl-10 position-relative">
                  <a
                    href="https://app.heyvisuals.com/"
                    className="btn mt-5 home-btn"
                    style={{ boxShadow: '0 5px 35px rgb(255 255 255 / 40%)' }}
                    role="button"
                  >
                    Start for free
                  </a>

                  <a
                    href="https://app.heyvisuals.com/"
                    className="btn-shine2 text-white d-flex align-items-center d-block d-xl-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ paddingTop: '130px' }}
                  >
                    <i className="fs-2 bi bi-info-circle pe-2"></i>
                    <span>No credit card required</span>
                  </a>
                </div>
                <div className="col-xl-12 col-md-6 d-none d-xl-block pt-xl-10 position-relative mt-5">
                  <a
                    href="https://app.heyvisuals.com/"
                    className="btn-shine2 text-white d-flex align-items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fs-2 bi bi-info-circle pe-2"></i>
                    <span>No credit card required</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="testimonial-area bg_color_black py-10" style={{ background: "#020202", overflow: 'hidden' }}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {/* Section Title (Optional) */}
                {/* 
            <div className="section-title text-center">
              <h2 className="title text_color_white text-anim">What our users are saying</h2>
              <p className="desc text_color_light_white max-width-728 section-middle">
                Explore the authentic voices of user satisfaction in our testimonial section. Echoing diverse and exceptional experiences with our products.
              </p>
            </div>
            */}
              </div>
            </div>
            <div className='bot-secd'>
              {/* Top Slider */}
              <div className="row pt-60 gy-4">
                <div className="col-lg-12 ps-0 pe-0">
                  <Slider {...settings}>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider0} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider1} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider2} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider3} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider4} alt="" className="w-100" />
                    </div>
                  </Slider>
                </div>
              </div>

              {/* Button Section */}
              <div className="Btn-sec ">
                <button className="create-btn mx-auto" target="_blank" onClick={() => window.location.href = 'https://app.heyvisuals.com/'}>
                  <div className="sign">+</div>
                  <div className="text">Create</div>
                </button>
              </div>

              {/* Bottom Slider */}
              <div className="row pt-60 gy-4">
                <div className="col-lg-12 ps-0 pe-0">
                  <Slider {...settings2}>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider5} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider6} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider7} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider8} alt="" className="w-100" />
                    </div>
                    <div className="testimonial-box shadow-sm">
                      <img src={blackslider9} alt="" className="w-100" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="faq-section" style={{ paddingBottom: '0px' }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <h1 className="fw-bold text-dark text-center py-xl-10 fs-4x">
              How <span className="text-linear">Hey Visuals</span> Works
            </h1>
            <div className="col-xl-6 d-xl-block d-md-none">
              <img src={illus} width="100%" alt="Illustration" />
            </div>
            <div className="col-xl-6 col-md-12 col-lg-12">
              <div className="card-body">
                {/* Section 1 */}
                <div className="faq-steps shadow-sm mb-5">
                  <div
                    className="d-flex align-items-center collapsible flex-wrap toggle mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq1"
                  >
                    <div className="d-flex align-items-center flex-grow-1">
                      <h3 className="fw-semibold cursor-pointer me-3 mb-0">
                        <span className="text-black-50">1. Create your</span> account
                      </h3>
                    </div>
                    <div className="ms-n1 me-4">
                      <FontAwesomeIcon icon={faChevronDown} className="toggle-on fs-2 text-custom" />
                      <FontAwesomeIcon icon={faChevronRight} className="toggle-off fs-2" />
                    </div>
                  </div>
                  <div id="faq1" className="collapse fs-6 ms-5 show">
                    <div className="mb-xl-4 mt-5">
                      <span className="text-dark steps-body fs-1-5fds">
                        First, sign up and get your account created. With Hey Visuals it's FREE.
                      </span>
                    </div>
                  </div>
                </div>

                {/* Section 2 */}
                <div className="faq-steps shadow-sm mb-5">
                  <div
                    className="d-flex align-items-center collapsible flex-wrap toggle collapsed mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2"
                  >
                    <div className="d-flex align-items-center flex-grow-1">
                      <h3 className="fw-semibold cursor-pointer me-3 mb-0">
                        <span className="text-black-50">2. Use the</span> intuitive Hey Visuals dashboard
                      </h3>
                    </div>
                    <div className="ms-n1 me-4">
                      <FontAwesomeIcon icon={faChevronDown} className="toggle-on fs-2 text-custom" />
                      <FontAwesomeIcon icon={faChevronRight} className="toggle-off fs-2" />
                    </div>
                  </div>
                  <div id="faq2" className="collapse fs-6 ms-5">
                    <div className="mb-xl-4 mt-5">
                      <span className="text-dark steps-body fs-1-5fds">
                        The dashboard of Hey Visuals is user-friendly and you will easily learn how to use it.
                      </span>
                    </div>
                  </div>
                </div>

                {/* Section 3 */}
                <div className="faq-steps shadow-sm mb-5">
                  <div
                    className="d-flex align-items-center collapsible flex-wrap toggle collapsed mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq3"
                  >
                    <div className="d-flex align-items-center flex-grow-1">
                      <h3 className="fw-semibold cursor-pointer me-3 mb-0">
                        <span className="text-black-50">3. To Create</span> captions
                      </h3>
                    </div>
                    <div className="ms-n1 me-4">
                      <FontAwesomeIcon icon={faChevronDown} className="toggle-on fs-2 text-custom" />
                      <FontAwesomeIcon icon={faChevronRight} className="toggle-off fs-2" />
                    </div>
                  </div>
                  <div id="faq3" className="collapse fs-6 ms-5">
                    <div className="mb-xl-4 mt-5">
                      <span className="text-dark steps-body fs-1-5fds">
                        Precisely, give the information about the post and Hey Visuals will write the captivating captions.
                      </span>
                    </div>
                  </div>
                </div>

                {/* sectioin 4 */}
                <div className="faq-steps shadow-sm mb-5">
                  <div
                    className="d-flex align-items-center collapsible flex-wrap toggle collapsed mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq4"
                  >
                    <div className="d-flex align-items-center flex-grow-1">
                      <h3 className="fw-semibold cursor-pointer me-3 mb-0">
                        <span className="text-black-50">4. To Create</span>  images
                      </h3>
                    </div>
                    <div className="ms-n1 me-4">
                      <FontAwesomeIcon icon={faChevronDown} className="toggle-on fs-2 text-custom" />
                      <FontAwesomeIcon icon={faChevronRight} className="toggle-off fs-2" />
                    </div>
                  </div>
                  <div id="faq4" className="collapse fs-6 ms-5">
                    <div className="mb-xl-4 mt-5">
                      <span className="text-dark steps-body fs-1-5fds">
                        Type in what kind of image you need from the tool and it will generate the enhanced images.
                      </span>
                    </div>
                  </div>
                </div>

                {/* section 5 */}
                <div className="faq-steps shadow-sm mb-5">
                  <div
                    className="d-flex align-items-center collapsible flex-wrap toggle collapsed mb-0"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq5"
                  >
                    <div className="d-flex align-items-center flex-grow-1">
                      <h3 className="fw-semibold cursor-pointer me-3 mb-0">
                        <span className="text-black-50">5. Use the </span>  generated content
                      </h3>
                    </div>
                    <div className="ms-n1 me-4">
                      <FontAwesomeIcon icon={faChevronDown} className="toggle-on fs-2 text-custom" />
                      <FontAwesomeIcon icon={faChevronRight} className="toggle-off fs-2" />
                    </div>
                  </div>
                  <div id="faq5" className="collapse fs-6 ms-5">
                    <div className="mb-xl-4 mt-5">
                      <span className="text-dark steps-body fs-1-5fds">
                        After the content generation, use the images and captions on Instagram and see the difference.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="subscribe-area bg_color_black position-relative z-index-one py-20" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="subscribe-wrap overflow-hidden position-relative z-index-one">
                <div className="section-title text-center">
                  <h2 className="title text-white fs-3x" style={{ perspective: '400px' }}>
                    <div
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        position: 'relative',
                        translate: 'none',
                        rotate: 'none',
                        scale: 'none',
                        transformOrigin: '628px 36px',
                        transform: 'translate3d(0px, 0px, 0px)',
                        opacity: 1,
                      }}
                    >
                      Hey Visuals is a revolutionary AI for Instagram users and celebrities who want to create content in seconds.
                    </div>
                  </h2>
                  <p className="desc fs-3 text-white">
                    It offers the platform to create AI-generated images and text that will help you grow faster with captivating creations.
                  </p>
                </div>
                <div className="two-btn-wrap mt-4 mt-lg-5 d-flex flex-wrap justify-content-center">
                  <a
                    className="common-btn btn btn-light  rounded-5 fs-3 border border-light"
                    href="https://app.heyvisuals.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="../../external.html?link=http://www.w3.org/2000/svg">
                      <path
                        d="M21.7061 12.7083L14.707 19.7073C14.512 19.9023 14.256 20.0003 14 20.0003C13.744 20.0003 13.488 19.9023 13.293 19.7073C12.902 19.3163 12.902 18.6842 13.293 18.2933L18.5859 13.0003H3C2.447 13.0003 2 12.5523 2 12.0003C2 11.4483 2.447 11.0003 3 11.0003H18.5859L13.293 5.70731C12.902 5.31631 12.902 4.68425 13.293 4.29325C13.684 3.90225 14.316 3.90225 14.707 4.29325L21.7061 11.2923C21.7991 11.3853 21.8721 11.4952 21.9231 11.6182C22.0241 11.8622 22.0241 12.1384 21.9231 12.3824C21.8721 12.5054 21.7991 12.6153 21.7061 12.7083Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </a>
                </div>
                <img
                  className="position-absolute z-index-minus-one start-0 top-0 h-100 w-100"
                  src={backnoice}
                  alt="Background Noise"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

 
      <section>
        <footer
          className="footer mt-auto py-xl-6 py-4"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.37)', border: '1.5px solid #fff', backdropFilter: 'blur(10px)', }} >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="footer-logo">
                  <img
                    src={footerimage}
                    alt="Hey Visuals Logo"
                    style={{ maxWidth: '200px', width: '100%' }}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="footer-content d-md-flex align-items-center justify-content-end text-center">
                  <div className="footer__links me-md-10 fs-4 fw-semibold">
                    <a href="term-conditions.html" className="text-custom">
                      Terms
                    </a>
                    &nbsp;and&nbsp;
                    <a href="#test" className="text-custom">
                      Privacy
                    </a>
                  </div>
                  <div className="footer__copyright fs-4 fw-semibold">
                    © 2024 Hey Visuals. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  )
}

export default Navbar;
