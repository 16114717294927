import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import React, { useState, useEffect } from 'react';
import FlashSaleModal from './Components/FlashSaleModal';

function App() {
  const [isModalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setModalOpen(true), 1000); 
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Navbar />} />
        </Routes>
      </Router>

      {/* FlashSaleModal component */}
      <FlashSaleModal />
    </div>
  );
}

export default App;
