import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css"; // For Bootstrap styles
import '../assets/css/style.css';
import { Modal } from 'react-bootstrap';

const FlashSaleModal = () => {
  // State to control the visibility of the modal
  const [isModalVisible, setIsModalVisible] = useState(true);

  // State to store the countdown
  const [countdown, setCountdown] = useState('');

  // Function to hide the modal
  const closeModal = () => {
    setIsModalVisible(false); // Set modal visibility to false when closed
  };

  // Function to get the next expiration date (midnight of the next day)
  const getNextExpirationDate = (currentDate) => {
    let nextExpirationDate = new Date(currentDate);
    nextExpirationDate.setHours(57, 0, 0, 0); // Midnight of the next day
    return nextExpirationDate;
  };

  // Countdown Timer Effect (updates every second)
  useEffect(() => {
    const expiration = getNextExpirationDate(new Date());
    const callTime = expiration.getTime();

    const interval = setInterval(() => {
      const now = new Date();
      const nowTime = now.getTime();
      const distance = callTime - nowTime;

      if (distance > 0) {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Update countdown state with the new time
        setCountdown(
         <p style={{ fontWeight: 'bold', fontSize: '24px', color: '#fff', width: '100%', border:'none', margin:'auto' }}>
            <span style={{ color: '#fff' }}>0 <small>  DAYS</small></span> 
             <span style={{ color: '#fff' ,}}> {hours}  <small> HRS</small></span> 
           <span style={{ color: '#fff' }}>  {minutes} <small> MINS</small></span> 
            <span style={{ color: '#fff' }}> {seconds} <small> SECS</small></span>
          </p>
        );
      } else {
        // Reset the timer if it reaches 0
        const newExpiration = getNextExpirationDate(new Date());
        setCountdown('0 DAYS 00 HRS 00 MINS 00 SECS');
      }
    }, 1000);

    // Cleanup the interval when component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <Modal show={isModalVisible} onHide={closeModal} centered>
      {isModalVisible && (
        <div
          className="modal fade show"
          id="flashSale"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="flashSaleTitle"
          aria-hidden="false"
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={{
                borderRadius: '24px',
                background: '#fff',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, .37)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <div className="col-lg-12">
                <div className="industries-box position-relative overflow-hidden">
                  <div
                    className="close-btn"
                    style={{ textAlign: 'end', position: 'absolute', right: '25px' }}
                  >
                    <button
                      type="button"
                      className="close p-0 pt-2 btn"
                      onClick={closeModal} // Close modal on click
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="bi fs-4 bi-x-lg fs-4w" style={{color:'fff'}}></i>
                      </span>
                    </button>
                  </div>

                  <div className="industries-info text-center">
                    <div className="card-content">
                      <h1 className="text-white top blueNeon text-center">
                        BLACK FRIDAY SALE
                      </h1>
                      <p className="fs-4 text-white disTwo text-center">
                        Get 50% off all plans
                      </p>

                      {/* Display the countdown timer */}
                      <div className="countdown_timer" id="countdown-timer1">
                        {countdown}
                      </div>
                    </div>
                    <a
                      href="https://app.heyvisuals.com/"
                      className="btn home-btn"
                      role="button"
                    >
                      CLAIM 50% OFF NOW
                    </a>
                    <p className="fs-4 text-white text-center mt-5">
                      Ready to unleash 🔥 your creativity with <strong>Hey visuals?</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </Modal>
    </>
  );
};


export default FlashSaleModal;
